<template>
  <div class="flex flex-col mt-2 w-full input--range">
    <label class="mb-6 input">
      <div ref="slider" />
    </label>

    <div class="flex flex-col gap-4">
      <div>
        <p>Min</p>

        <input
          v-model.number="values.min"
          type="number"
          class="p-2 w-auto max-w-full text-white-default bg-blue-light rounded"
          @input="onKeyInput"
        />
      </div>

      <div>
        <p>Max</p>

        <input
          v-model.number="values.max"
          type="number"
          class="p-2 w-auto max-w-full text-white-default bg-blue-light rounded"
          @input="onKeyInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import noUiSlider from "nouislider";

export default {
  name: "Input",

  props: {
    min: {
      type: [Number, String],
      required: true,
    },
    max: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      values: {
        min: 0,
        max: 0,
      },
    };
  },

  watch: {
    values: {
      deep: true,

      handler() {
        this.$emit("sliderChange", this.values);
      },
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.min = parseInt(this.min, 10);
      this.max = parseInt(this.max, 10);

      this.values.min = this.min;
      this.values.max = this.max;

      noUiSlider.create(this.$refs.slider, {
        range: {
          min: this.min,
          max: this.max,
        },
        start: [0, this.max],
        handles: [this.min, this.max],
        connect: true,
      });

      this.onSlide();
    },

    onSlide() {
      this.$refs.slider.noUiSlider.on("slide", (values) => {
        this.values.min = parseInt(values[0], 10);
        this.values.max = parseInt(values[1], 10);
      });
    },

    onKeyInput() {
      if (parseInt(this.values.min, 10) < parseInt(this.min, 10)) {
        this.values.min = parseInt(this.min, 10);
      }

      if (parseInt(this.values.max, 10) > parseInt(this.max, 10)) {
        this.values.max = parseInt(this.max, 10);
      }

      this.$refs.slider.noUiSlider.set([parseInt(this.values.min, 10), parseInt(this.values.max, 10)]);
    },
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/nouislider/distribute/nouislider.min.css";
@import "../../styles/globals";
@import "../../styles/colors";
@import "../../styles/fonts";

.input--range {
  width: 100%;
  display: flex;

  .noUi-horizontal,
  .noUi-base {
    width: 100%;
    display: flex;
  }

  .noUi-horizontal {
    flex-direction: column;
    height: 0.25rem;
    border: 0 !important;
    box-shadow: none !important;
    background-color: color(blue, light);
  }

  .noUi-connects {
    width: 100%;
    display: flex;
  }

  .input__value {
    display: flex;
    width: 50%;
    align-items: center;

    > span {
      margin-right: 0.5rem;
      color: color(blue, light);
      font-size: 0.875rem;
    }

    &:last-child {
      margin-left: auto;
      justify-content: flex-end;
    }
  }

  .input__field {
    width: 4.5rem;
    height: 1.5rem;
    border: 0;
    outline: 0;
    text-align: right;
    padding: 0 0.5rem;
    color: color(white, base);

    font-size: 0.875rem;
  }
}

.noUi-handle {
  background: color(cta, secondary) !important;
  width: 1.125rem !important;
  height: 1.125rem !important;
  outline: 0 !important;
  top: -7px !important;
  box-shadow: none !important;
  border: 0 !important;
  cursor: pointer !important;
  border-radius: $border-radius !important;

  &:hover {
    background-color: color(cta, secondary-hover) !important;
  }

  &::before,
  &::after {
    display: none !important;
  }
}

.noUi-connect {
  background: rgba(45, 40, 73, var(--tw-bg-opacity)) !important;
}

.noUi-target {
  padding: 0 1.125rem 0 0.25rem;
}

.noUi-connects {
  margin: 0 -1.125rem 0 -0.125rem;
  width: calc(100% + 1.125rem);
}
</style>
